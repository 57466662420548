@import "tailwindcss/base";

@import "./base.css";

[id^="yfDashContainer"] {
  width: 100% !important;
}

[id^="yfDashContainer"] img {
  margin: 0px;
}

[id^="yfDashContainer"] .yfDash,
[id^="yfDashContainer"] .yfDashReport,
[id^="yfDashContainer"] .yfDashTitleOuter,
[id^="yfDashContainer"] .yfReportTitleOuter,
[id^="yfDashContainer"] .yfReport,
[id^="yfDashContainer"] .yfDashFooter,
[id^="yfDashContainer"] .yfReportFooter,
[id^="yfDashContainer"] .reportChart {
  width: 100% !important;
}

[id^="yfDashContainer"] .yfDash .img,
[id^="yfDashContainer"] .yfDashReport .img,
[id^="yfDashContainer"] .yfDashTitleOuter .img,
[id^="yfDashContainer"] .yfReportTitleOuter .img,
[id^="yfDashContainer"] .yfReport .img,
[id^="yfDashContainer"] .yfDashFooter .img,
[id^="yfDashContainer"] .yfReportFooter .img,
[id^="yfDashContainer"] .reportChart .img {
  width: 100% !important;
}

[id^="yfDashContainer"] .yfDash table, 
[id^="yfDashContainer"] .yfDashReport table, 
[id^="yfDashContainer"] .yfDashTitleOuter table, 
[id^="yfDashContainer"] .yfReportTitleOuter table, 
[id^="yfDashContainer"] .yfReport table, 
[id^="yfDashContainer"] .yfDashFooter table, 
[id^="yfDashContainer"] .yfReportFooter table, 
[id^="yfDashContainer"] .reportChart table {
  width: 100% !important; 
}

[id^="yfDashContainer"] .reportChart {
  min-width: initial !important;
}