.rte--reverse {
  @apply text-white;
}

.rte h2 {
  @apply text-2xl font-bold leading-tight mb-7;

  @screen lg {
    @apply text-4xl;
  }
}

.rte h3 {
  @apply text-xl font-bold leading-tight mb-5;
}

.rte h2,
.rte h3 {
  @apply mt-10;
}

.rte h2:first-child,
.rte h3:first-child {
  @apply mt-0;
}

.rte p,
.rte ul,
.rte ol,
.rte blockquote {
  @apply mb-5;
}

.rte p:last-child,
.rte ul:last-child,
.rte ol:last-child,
.rte blockquote:last-child {
  @apply mb-0;
}

.rte ul {
  @apply list-disc pl-5;
}

.rte ul > li {
  @apply pl-2;
}

.rte ol {
  counter-reset: decimal;
}

.rte ol > li {
  counter-increment: decimal;
  @apply table;
}

.rte ol > li:before {
  content: counters(decimal, ".") ".";
  @apply table-cell min-w-7 pr-1;
}

.rte a {
  @apply text-blue opacity-100 bg-transparent transition duration-base rounded-sm underline;
}

.rte--reverse a {
  @apply text-inherit;
}

.rte a:hover {
  @apply opacity-50;
}

.rte blockquote {
  @apply text-blue border-blue border-l-2 pl-8 font-bold text-lg italic leading-tight;

  @screen lg {
    @apply text-xl;
  }
}

.reverse-text .rte blockquote {
  @apply text-white border-white;
}

.rte table {
  @apply w-full;
}
