.flexible-content-upper-table {
  @apply table-fixed border border-b-0 border-gray w-full bg-white;

  @screen md {
    @apply border-b;
  }

  thead {
    @apply hidden;

    @screen md {
      @apply bg-gray-lighter;
      display: table-header-group;
    }
  }

  tr {
    @apply block;

    @screen md {
      @apply table-row;
    }
  }

  th {
    @apply hidden;

    @screen md {
      @apply table-cell p-5 border-r border-gray text-md;
    }
  }

  td {
    @apply block py-2 px-3 border-gray;

    &:before {
      content: attr(data-th);
      @apply font-bold block uppercase text-sm leading-tight mb-1 tracking-wide;
    }

    &:first-child {
      @apply pt-3;
    }

    &:last-child {
      @apply pb-3 border-b;
    }

    @screen md {
      @apply table-cell align-top text-left px-5 py-4 border-r;

      &:before {
        content: none;
      }

      &:first-child {
        @apply pt-4;
      }

      &:last-child {
        @apply pb-4 border-b-0;
      }
    }
  }
}
