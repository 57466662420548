.ff-form-errors {
  @apply text-lg text-red font-bold mb-8; 
}

.ff-form-success {
  @apply text-lg text-blue font-bold mb-8; 
}

.ff-errors {
  @apply text-red list-none pl-0 mt-2 !important;
}

.ff-errors li {
  @apply pl-0 !important;
}

.ff-has-errors {
  @apply border-red;
}

.opinion-scale-scales {
  @apply flex;

  li {
    @apply flex-1 bg-white border border-gray-light border-l-0 relative;

    &:first-child {
      @apply border-l;
    }
  }

  input {
    @apply hidden;
  }

  label {
    @apply block p-2 text-center text-sm select-none transition duration-base cursor-pointer;

    &:hover {
      @apply bg-blue bg-opacity-15;
    }
  }

  :checked + label {
    @apply bg-blue text-white;
  }
}

.opinion-scale-legends {
  @apply flex justify-between mt-2;
}

.form-rating-field-wrapper {
  @apply flex justify-end relative leading-none gap-x-1;
  direction: rtl;

  input {
    @apply hidden;
  }

  label {
    @apply text-3xl text-gray-light;
    
    &::before {
      content: "\2605"
    }
  }

  :checked + label,
  :checked + label ~ label {
    color: gold;
  }
}

[data-signature-field] {
  @apply border bg-white max-w-full transition duration-base;
  border-color: theme(colors.gray-light) !important;

  &:hover {
    @apply shadow-m2;
    border-color: theme(colors.blue) !important;
  }
}

[data-freeform-file-upload] {
  @apply bg-white flex flex-col gap-y-4;

  > :empty {
    @apply hidden;
  }

  [data-file-preview] {
    @apply grid gap-2 items-center justify-items-center border-t pt-4;
    grid-template-columns: 150px 1fr 1fr;
  }

  [data-thumbnail] {
    @apply bg-cover h-0 w-full relative border;
    padding-bottom: 100%;
  }

  [data-extension-label] {
    @apply p-1 bg-white absolute top-0 left-0 border-r border-b;
    font-size: 10px;
  }

  [data-remove-button] {
    @apply absolute top-0 right-0 p-1 cursor-pointer;
  }
}

[data-freeform-table] {
  @apply w-full border-collapse appearance-none leading-tight;
  min-width: theme(screens.sm);
  
  th, td {
    @apply align-top;

    &:first-child {
      @apply pl-0;
    }

    &:last-child {
      @apply pr-0;
    }
  }

  th {
    @apply pt-0 pb-1 px-3 font-bold text-left;
  }

  td {
    @apply py-1 px-3;
  }
}

[data-signature-clear],
[data-freeform-table-remove-row],
[data-freeform-table-add-row] {
  @apply border border-gray-medium py-3 px-4 transition duration-base whitespace-no-wrap;

  &:hover {
    @apply border-blue shadow-m2;
  }
}

[data-freeform-table-remove-row] {
  @apply w-full;
}

[data-freeform-table-add-row] {
  @apply mt-2;
}