<template>
  <transition name="flyout-menu-layout-transition">
    <div
      v-if="active"
      ref="container"
      class="flyout-menu-layout fixed bottom-0 left-0 w-full md:max-w-96 bg-gray-alt-2 overflow-y-auto border"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script>
export default {
  name: "FlyoutMenuLayout",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    level: {
      type: Number,
      required: true,
    },
  },
  watch: {
    level() {
      this.$refs.container.scrollTop = 0;
    },
  },
};
</script>

<style scoped>
.flyout-menu-layout {
  top: theme("height.header-compact");
  z-index: 9999;
}

@screen lg {
  .flyout-menu-layout {
    @apply top-0;
  }
}

.flyout-menu-layout-transition-enter,
.flyout-menu-layout-transition-leave-to {
  @apply transform -translate-x-full;
}

.flyout-menu-layout-transition-enter-active,
.flyout-menu-layout-transition-leave-active {
  @apply transition-transform duration-300;
}

.flyout-menu-layout-transition-enter-to,
.flyout-menu-layout-transition-leave {
  @apply transform translate-x-0;
}
</style>
