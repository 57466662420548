@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Light.woff2") format("woff2"),
    url("../fonts/Aeonik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-LightItalic.woff2") format("woff2"),
    url("../fonts/Aeonik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Regular.woff2") format("woff2"),
    url("../fonts/Aeonik-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-RegularItalic.woff2") format("woff2"),
    url("../fonts/Aeonik-RegularItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Bold.woff2") format("woff2"),
    url("../fonts/Aeonik-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-BoldItalic.woff2") format("woff2"),
    url("../fonts/Aeonik-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

:root {
  --container-size: 80rem;
  --outside-gutter-size: 0.875rem;
  --gutter-size: 0.875rem;
}

@screen xs {
  :root {
    --outside-gutter-size: 1.25rem;
    --gutter-size: 1.25rem;
  }
}

@screen sm {
  :root {
    --outside-gutter-size: 2rem;
    --gutter-size: 2rem;
  }
}

/*Fade Transition*/
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-base;
}

.slide-from-right-enter-to,
.slide-from-right-leave {
  @apply opacity-100;
}

/*Fade Transition*/
.fade-enter,
.fade-leave-to {
  @apply opacity-0;
}

.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity duration-base;
}

/*Cross Fade Transition*/
.cross-fade-enter,
.cross-fade-leave-to {
  @apply opacity-0;
}

.cross-fade-enter-active,
.cross-fade-leave-active {
  @apply transition-opacity duration-base;
}

.cross-fade-leave-active {
  @apply absolute top-0 inset-x-0;
}

.cross-fade-enter-to,
.cross-fade-leave {
  @apply opacity-100;
}

[v-cloak] {
  @apply hidden;
}

/* Global Accessability */

.focus-visible:focus {
  @apply outline-none;
}

.focus-visible {
  @apply shadow-outline !important;
}
