.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--space-y-reverse))
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--space-y-reverse))
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--space-y-reverse))
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--space-x-reverse)))
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1rem * var(--space-y-reverse))
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse)))
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--space-y-reverse))
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse)))
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--space-y-reverse))
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
}

.space-y-7 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--space-y-reverse))
}

.space-x-7 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse)))
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2rem * var(--space-y-reverse))
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse)))
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--space-y-reverse))
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(3rem * var(--space-y-reverse))
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(4rem * var(--space-y-reverse))
}

.space-x-gs > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(var(--gutter-size) * var(--space-x-reverse));
  margin-left: calc(var(--gutter-size) * calc(1 - var(--space-x-reverse)))
}

.space-y-2\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--space-y-reverse))
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.appearance-none {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none
}

.bg-transparent {
  background-color: transparent
}

.bg-white {
  --bg-opacity: 1;
  background-color: white;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-black {
  --bg-opacity: 1;
  background-color: black;
  background-color: rgba(0, 0, 0, var(--bg-opacity))
}

.bg-gray {
  --bg-opacity: 1;
  background-color: #B5B7BB;
  background-color: rgba(181, 183, 187, var(--bg-opacity))
}

.bg-gray-light {
  --bg-opacity: 1;
  background-color: #DDDDDD;
  background-color: rgba(221, 221, 221, var(--bg-opacity))
}

.bg-gray-lighter {
  --bg-opacity: 1;
  background-color: #EDEDEE;
  background-color: rgba(237, 237, 238, var(--bg-opacity))
}

.bg-gray-lightest {
  --bg-opacity: 1;
  background-color: #F9F9F9;
  background-color: rgba(249, 249, 249, var(--bg-opacity))
}

.bg-gray-darker {
  --bg-opacity: 1;
  background-color: #393D44;
  background-color: rgba(57, 61, 68, var(--bg-opacity))
}

.bg-gray-darkest {
  --bg-opacity: 1;
  background-color: #32353B;
  background-color: rgba(50, 53, 59, var(--bg-opacity))
}

.bg-gray-alt-2 {
  --bg-opacity: 1;
  background-color: #343A40;
  background-color: rgba(52, 58, 64, var(--bg-opacity))
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #145DA0;
  background-color: rgba(20, 93, 160, var(--bg-opacity))
}

.bg-blue-dark {
  --bg-opacity: 1;
  background-color: #2C3039;
  background-color: rgba(44, 48, 57, var(--bg-opacity))
}

.bg-teal {
  --bg-opacity: 1;
  background-color: #00718B;
  background-color: rgba(0, 113, 139, var(--bg-opacity))
}

.bg-red {
  --bg-opacity: 1;
  background-color: #AD4B5C;
  background-color: rgba(173, 75, 92, var(--bg-opacity))
}

.bg-green {
  --bg-opacity: 1;
  background-color: #437A39;
  background-color: rgba(67, 122, 57, var(--bg-opacity))
}

.hover\:bg-gray-lightest:hover {
  --bg-opacity: 1;
  background-color: #F9F9F9;
  background-color: rgba(249, 249, 249, var(--bg-opacity))
}

.hover\:bg-gray-darkest:hover {
  --bg-opacity: 1;
  background-color: #32353B;
  background-color: rgba(50, 53, 59, var(--bg-opacity))
}

.hover\:bg-blue-dark:hover {
  --bg-opacity: 1;
  background-color: #2C3039;
  background-color: rgba(44, 48, 57, var(--bg-opacity))
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: white;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.group:hover .group-hover\:bg-blue-bright {
  --bg-opacity: 1;
  background-color: #00BCE7;
  background-color: rgba(0, 188, 231, var(--bg-opacity))
}

.bg-gradient-to-t {
  background-image: -webkit-gradient(linear, left bottom, left top, from(var(--gradient-color-stops)));
  background-image: linear-gradient(to top, var(--gradient-color-stops))
}

.bg-divider {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(204,204,204,0)), color-stop(50%, rgba(204,204,204,1)), to(rgba(204,204,204,0)));
  background-image: linear-gradient(90deg, rgba(204,204,204,0) 0%, rgba(204,204,204,1) 50%, rgba(204,204,204,0) 100%)
}

.bg-divider-blue {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(204,204,204,0)), color-stop(50%, #145DA0), to(rgba(204,204,204,0.00)));
  background-image: linear-gradient(90deg, rgba(204,204,204,0) 0%, #145DA0 50%, rgba(204,204,204,0.00) 100%)
}

.bg-gradient {
  background-image: -webkit-gradient(linear, left top, right top, from(#6B7EA9), color-stop(100%, #145DA0), to(#0290B1));
  background-image: linear-gradient(90deg, #6B7EA9 0%, #145DA0 100%, #0290B1 100%)
}

.bg-pattern-alt {
  background-image: url('/assets/bg-pattern-alt.svg')
}

.from-white {
  --gradient-from-color: white;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, rgba(255, 255, 255, 0))
}

.to-transparent {
  --gradient-to-color: transparent
}

.bg-opacity-8 {
  --bg-opacity: .08
}

.bg-opacity-50 {
  --bg-opacity: 0.5
}

.bg-bottom {
  background-position: bottom
}

.bg-center {
  background-position: center
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-cover {
  background-size: cover
}

.bg-full {
  background-size: 100%
}

.border-collapse {
  border-collapse: collapse
}

.border-inherit {
  border-color: inherit
}

.border-white {
  --border-opacity: 1;
  border-color: white;
  border-color: rgba(255, 255, 255, var(--border-opacity))
}

.border-gray {
  --border-opacity: 1;
  border-color: #B5B7BB;
  border-color: rgba(181, 183, 187, var(--border-opacity))
}

.border-gray-light {
  --border-opacity: 1;
  border-color: #DDDDDD;
  border-color: rgba(221, 221, 221, var(--border-opacity))
}

.border-gray-medium {
  --border-opacity: 1;
  border-color: #CCCCCC;
  border-color: rgba(204, 204, 204, var(--border-opacity))
}

.border-blue {
  --border-opacity: 1;
  border-color: #145DA0;
  border-color: rgba(20, 93, 160, var(--border-opacity))
}

.border-teal {
  --border-opacity: 1;
  border-color: #00718B;
  border-color: rgba(0, 113, 139, var(--border-opacity))
}

.border-red {
  --border-opacity: 1;
  border-color: #AD4B5C;
  border-color: rgba(173, 75, 92, var(--border-opacity))
}

.border-green {
  --border-opacity: 1;
  border-color: #437A39;
  border-color: rgba(67, 122, 57, var(--border-opacity))
}

.rounded-sm {
  border-radius: 0.1875rem
}

.rounded {
  border-radius: 0.25rem
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-full {
  border-radius: 9999px
}

.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem
}

.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem
}

.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem
}

.border-solid {
  border-style: solid
}

.border-dashed {
  border-style: dashed
}

.border-0 {
  border-width: 0
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-2\.5 {
  border-width: 0.625rem
}

.border-b-0 {
  border-bottom-width: 0
}

.border-b-2 {
  border-bottom-width: 2px
}

.border-t {
  border-top-width: 1px
}

.border-b {
  border-bottom-width: 1px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.group:hover .group-hover\:block {
  display: block
}

.last\:hidden:last-child {
  display: none
}

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column
}

.flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse
}

.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
      flex-wrap: wrap-reverse
}

.items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start
}

.items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end
}

.items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center
}

.self-center {
  -ms-flex-item-align: center;
      align-self: center
}

.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start
}

.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end
}

.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center
}

.justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between
}

.flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1
}

.flex-shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0
}

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1
}

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2
}

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3
}

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4
}

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5
}

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6
}

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7
}

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8
}

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9
}

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10
}

.order-last {
  -webkit-box-ordinal-group: 10000;
      -ms-flex-order: 9999;
          order: 9999
}

.font-sans {
  font-family: Aeonik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-0 {
  height: 0
}

.h-3 {
  height: 0.75rem
}

.h-4 {
  height: 1rem
}

.h-5 {
  height: 1.25rem
}

.h-6 {
  height: 1.5rem
}

.h-7 {
  height: 1.75rem
}

.h-8 {
  height: 2rem
}

.h-9 {
  height: 2.25rem
}

.h-10 {
  height: 2.5rem
}

.h-12 {
  height: 3rem
}

.h-16 {
  height: 4rem
}

.h-20 {
  height: 5rem
}

.h-24 {
  height: 6rem
}

.h-32 {
  height: 8rem
}

.h-42 {
  height: 10.5rem
}

.h-px {
  height: 1px
}

.h-2px {
  height: 2px
}

.h-4px {
  height: 4px
}

.h-3\.5 {
  height: 0.875rem
}

.h-4\.5 {
  height: 1.125rem
}

.h-7\.5 {
  height: 1.875rem
}

.h-12\.5 {
  height: 3.125rem
}

.h-full {
  height: 100%
}

.h-header-compact {
  height: 5.625rem
}

.text-2xs {
  font-size: 8px
}

.text-xs {
  font-size: 13px
}

.text-sm {
  font-size: 14px
}

.text-base {
  font-size: 16px
}

.text-md {
  font-size: 18px
}

.text-lg {
  font-size: 20px
}

.text-xl {
  font-size: 22px
}

.text-2xl {
  font-size: 26px
}

.text-3xl {
  font-size: 32px
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.leading-snug {
  line-height: 1.375
}

.leading-normal {
  line-height: 1.5
}

.list-disc {
  list-style-type: disc
}

.m-1 {
  margin: 0.25rem
}

.m-3 {
  margin: 0.75rem
}

.m-5 {
  margin: 1.25rem
}

.m-10 {
  margin: 2.5rem
}

.-m-1 {
  margin: -0.25rem
}

.-m-2 {
  margin: -0.5rem
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem
}

.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem
}

.mr-0 {
  margin-right: 0
}

.mb-0 {
  margin-bottom: 0
}

.ml-0 {
  margin-left: 0
}

.mt-1 {
  margin-top: 0.25rem
}

.mr-1 {
  margin-right: 0.25rem
}

.mb-1 {
  margin-bottom: 0.25rem
}

.ml-1 {
  margin-left: 0.25rem
}

.mt-2 {
  margin-top: 0.5rem
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mt-3 {
  margin-top: 0.75rem
}

.mr-3 {
  margin-right: 0.75rem
}

.mb-3 {
  margin-bottom: 0.75rem
}

.mt-4 {
  margin-top: 1rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mt-5 {
  margin-top: 1.25rem
}

.mr-5 {
  margin-right: 1.25rem
}

.mb-5 {
  margin-bottom: 1.25rem
}

.mt-6 {
  margin-top: 1.5rem
}

.mb-6 {
  margin-bottom: 1.5rem
}

.mb-7 {
  margin-bottom: 1.75rem
}

.mt-8 {
  margin-top: 2rem
}

.mb-8 {
  margin-bottom: 2rem
}

.mt-10 {
  margin-top: 2.5rem
}

.mb-10 {
  margin-bottom: 2.5rem
}

.ml-10 {
  margin-left: 2.5rem
}

.mt-12 {
  margin-top: 3rem
}

.mb-12 {
  margin-bottom: 3rem
}

.mb-15 {
  margin-bottom: 3.75rem
}

.mb-16 {
  margin-bottom: 4rem
}

.mt-auto {
  margin-top: auto
}

.ml-auto {
  margin-left: auto
}

.mt-px {
  margin-top: 1px
}

.mb-px {
  margin-bottom: 1px
}

.mr-gs {
  margin-right: var(--gutter-size)
}

.mb-7\.5 {
  margin-bottom: 1.875rem
}

.-mt-2 {
  margin-top: -0.5rem
}

.-mb-2 {
  margin-bottom: -0.5rem
}

.-ml-7 {
  margin-left: -1.75rem
}

.-mb-gs {
  margin-bottom: calc(var(--gutter-size) * -1)
}

.-ml-gs {
  margin-left: calc(var(--gutter-size) * -1)
}

.-mt-2px {
  margin-top: -2px
}

.-mr-2px {
  margin-right: -2px
}

.-mb-2px {
  margin-bottom: -2px
}

.last\:mb-0:last-child {
  margin-bottom: 0
}

.max-h-64 {
  max-height: 16rem
}

.max-w-xs {
  max-width: 20rem
}

.max-w-md {
  max-width: 28rem
}

.max-w-lg {
  max-width: 32rem
}

.max-w-xl {
  max-width: 36rem
}

.min-h-full {
  min-height: 100%
}

.min-w-0 {
  min-width: 0
}

.min-w-3 {
  min-width: 0.75rem
}

.min-w-full {
  min-width: 100%
}

.min-w-auto {
  min-width: auto
}

.min-w-xxs {
  min-width: 12.5rem
}

.min-w-md {
  min-width: 20rem
}

.min-w-7xl {
  min-width: 100rem
}

.object-contain {
  -o-object-fit: contain;
     object-fit: contain
}

.object-cover {
  -o-object-fit: cover;
     object-fit: cover
}

.object-bottom {
  -o-object-position: bottom;
     object-position: bottom
}

.opacity-0 {
  opacity: 0
}

.opacity-15 {
  opacity: .15
}

.opacity-25 {
  opacity: 0.25
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.opacity-100 {
  opacity: 1
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.overscroll-contain {
  -ms-scroll-chaining: none;
      overscroll-behavior: contain
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.p-5 {
  padding: 1.25rem
}

.p-6 {
  padding: 1.5rem
}

.p-8 {
  padding: 2rem
}

.p-10 {
  padding: 2.5rem
}

.p-ogs {
  padding: var(--outside-gutter-size)
}

.p-1\.5 {
  padding: 0.375rem
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem
}

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem
}

.px-gs {
  padding-left: var(--gutter-size);
  padding-right: var(--gutter-size)
}

.px-ogs {
  padding-left: var(--outside-gutter-size);
  padding-right: var(--outside-gutter-size)
}

.px-2px {
  padding-left: 2px;
  padding-right: 2px
}

.py-3px {
  padding-top: 3px;
  padding-bottom: 3px
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem
}

.px-5\.5 {
  padding-left: 1.375rem;
  padding-right: 1.375rem
}

.py-7\.5 {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem
}

.px-7\.5 {
  padding-left: 1.875rem;
  padding-right: 1.875rem
}

.pb-0 {
  padding-bottom: 0
}

.pb-1 {
  padding-bottom: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pt-3 {
  padding-top: 0.75rem
}

.pr-3 {
  padding-right: 0.75rem
}

.pb-3 {
  padding-bottom: 0.75rem
}

.pl-3 {
  padding-left: 0.75rem
}

.pt-4 {
  padding-top: 1rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-4 {
  padding-left: 1rem
}

.pt-5 {
  padding-top: 1.25rem
}

.pb-5 {
  padding-bottom: 1.25rem
}

.pl-5 {
  padding-left: 1.25rem
}

.pl-7 {
  padding-left: 1.75rem
}

.pt-8 {
  padding-top: 2rem
}

.pr-8 {
  padding-right: 2rem
}

.pt-9 {
  padding-top: 2.25rem
}

.pt-10 {
  padding-top: 2.5rem
}

.pr-10 {
  padding-right: 2.5rem
}

.pb-10 {
  padding-bottom: 2.5rem
}

.pl-10 {
  padding-left: 2.5rem
}

.pt-12 {
  padding-top: 3rem
}

.pr-12 {
  padding-right: 3rem
}

.pb-12 {
  padding-bottom: 3rem
}

.pb-14 {
  padding-bottom: 3.5rem
}

.pt-15 {
  padding-top: 3.75rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pb-20 {
  padding-bottom: 5rem
}

.pb-gs {
  padding-bottom: var(--gutter-size)
}

.pl-gs {
  padding-left: var(--gutter-size)
}

.pt-9\/16 {
  padding-top: 56.25%
}

.pt-5\/8 {
  padding-top: 62.5%
}

.pt-7\/9 {
  padding-top: 77.777778%
}

.pointer-events-none {
  pointer-events: none
}

.pointer-events-auto {
  pointer-events: auto
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.inset-y-0 {
  top: 0;
  bottom: 0
}

.inset-x-0 {
  right: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.right-5 {
  right: 1.25rem
}

.top-full {
  top: 100%
}

.top-1\/2 {
  top: 50%
}

.shadow-lg {
  -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
          box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.shadow-m3 {
  -webkit-box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08), 0 10px 20px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.08), 0 10px 20px 0 rgba(0, 0, 0, 0.15)
}

.fill-current {
  fill: currentColor
}

.stroke-current {
  stroke: currentColor
}

.stroke-2 {
  stroke-width: 2
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-white {
  --text-opacity: 1;
  color: white;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-black {
  --text-opacity: 1;
  color: black;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.text-gray {
  --text-opacity: 1;
  color: #B5B7BB;
  color: rgba(181, 183, 187, var(--text-opacity))
}

.text-gray-light {
  --text-opacity: 1;
  color: #DDDDDD;
  color: rgba(221, 221, 221, var(--text-opacity))
}

.text-gray-lighter {
  --text-opacity: 1;
  color: #EDEDEE;
  color: rgba(237, 237, 238, var(--text-opacity))
}

.text-gray-dark {
  --text-opacity: 1;
  color: #474C55;
  color: rgba(71, 76, 85, var(--text-opacity))
}

.text-gray-alt {
  --text-opacity: 1;
  color: #767A80;
  color: rgba(118, 122, 128, var(--text-opacity))
}

.text-blue {
  --text-opacity: 1;
  color: #145DA0;
  color: rgba(20, 93, 160, var(--text-opacity))
}

.text-blue-bright {
  --text-opacity: 1;
  color: #00BCE7;
  color: rgba(0, 188, 231, var(--text-opacity))
}

.text-blue-dark {
  --text-opacity: 1;
  color: #2C3039;
  color: rgba(44, 48, 57, var(--text-opacity))
}

.text-teal {
  --text-opacity: 1;
  color: #00718B;
  color: rgba(0, 113, 139, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #AD4B5C;
  color: rgba(173, 75, 92, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #437A39;
  color: rgba(67, 122, 57, var(--text-opacity))
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: white;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.hover\:text-gray-darker:hover {
  --text-opacity: 1;
  color: #393D44;
  color: rgba(57, 61, 68, var(--text-opacity))
}

.hover\:text-blue-bright:hover {
  --text-opacity: 1;
  color: #00BCE7;
  color: rgba(0, 188, 231, var(--text-opacity))
}

.group:hover .group-hover\:text-blue-bright,.group-primary:hover .group-primary-hover\:text-blue-bright {
  --text-opacity: 1;
  color: #00BCE7;
  color: rgba(0, 188, 231, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.underline {
  text-decoration: underline
}

.hover\:underline:hover {
  text-decoration: underline
}

.focus\:underline:focus {
  text-decoration: underline
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.tracking-wider {
  letter-spacing: 0.05em
}

.align-top {
  vertical-align: top
}

.align-text-top {
  vertical-align: text-top
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.break-words {
  word-wrap: break-word;
  word-wrap: break-word
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-2 {
  width: 0.5rem
}

.w-3 {
  width: 0.75rem
}

.w-4 {
  width: 1rem
}

.w-5 {
  width: 1.25rem
}

.w-7 {
  width: 1.75rem
}

.w-8 {
  width: 2rem
}

.w-9 {
  width: 2.25rem
}

.w-10 {
  width: 2.5rem
}

.w-12 {
  width: 3rem
}

.w-17 {
  width: 4.25rem
}

.w-18 {
  width: 4.5rem
}

.w-20 {
  width: 5rem
}

.w-26 {
  width: 6.5rem
}

.w-32 {
  width: 8rem
}

.w-40 {
  width: 10rem
}

.w-42 {
  width: 10.5rem
}

.w-56 {
  width: 14rem
}

.w-64 {
  width: 16rem
}

.w-75 {
  width: 18.75rem
}

.w-auto {
  width: auto
}

.w-2\.75 {
  width: 0.6875rem
}

.w-3\.5 {
  width: 0.875rem
}

.w-4\.5 {
  width: 1.125rem
}

.w-7\.5 {
  width: 1.875rem
}

.w-12\.5 {
  width: 3.125rem
}

.w-1\/2 {
  width: 50%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-3\/4 {
  width: 75%
}

.w-1\/5 {
  width: 20%
}

.w-2\/5 {
  width: 40%
}

.w-3\/5 {
  width: 60%
}

.w-full {
  width: 100%
}

.hover\:w-auto:hover {
  width: auto
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-20 {
  z-index: 20
}

.z-30 {
  z-index: 30
}

.z-40 {
  z-index: 40
}

.z-50 {
  z-index: 50
}

.z-60 {
  z-index: 60
}

.gap-2 {
  grid-gap: 0.5rem;
  grid-gap: 0.5rem;
  gap: 0.5rem
}

.gap-3 {
  grid-gap: 0.75rem;
  grid-gap: 0.75rem;
  gap: 0.75rem
}

.gap-4 {
  grid-gap: 1rem;
  grid-gap: 1rem;
  gap: 1rem
}

.gap-gs {
  grid-gap: var(--gutter-size);
  grid-gap: var(--gutter-size);
  gap: var(--gutter-size)
}

.gap-x-2 {
  grid-column-gap: 0.5rem;
  grid-column-gap: 0.5rem;
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem
}

.gap-x-4 {
  grid-column-gap: 1rem;
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem
}

.gap-x-gs {
  grid-column-gap: var(--gutter-size);
  grid-column-gap: var(--gutter-size);
  -webkit-column-gap: var(--gutter-size);
     -moz-column-gap: var(--gutter-size);
          column-gap: var(--gutter-size)
}

.gap-y-2 {
  grid-row-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem
}

.gap-y-4 {
  grid-row-gap: 1rem;
  grid-row-gap: 1rem;
  row-gap: 1rem
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem
}

.gap-y-8 {
  grid-row-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem
}

.gap-y-12 {
  grid-row-gap: 3rem;
  grid-row-gap: 3rem;
  row-gap: 3rem
}

.gap-y-20 {
  grid-row-gap: 5rem;
  grid-row-gap: 5rem;
  row-gap: 5rem
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr))
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr))
}

.col-span-1 {
  grid-column: span 1 / span 1
}

.col-span-8 {
  grid-column: span 8 / span 8
}

.col-span-11 {
  grid-column: span 11 / span 11
}

.col-span-12 {
  grid-column: span 12 / span 12
}

.col-start-1 {
  grid-column-start: 1
}

.auto-rows-max {
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content
}

.row-span-2 {
  grid-row: span 2 / span 2
}

.row-start-1 {
  grid-row-start: 1
}

.row-start-2 {
  grid-row-start: 2
}

.row-start-3 {
  grid-row-start: 3
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.rotate-45 {
  --transform-rotate: 45deg
}

.rotate-180 {
  --transform-rotate: 180deg
}

.-rotate-45 {
  --transform-rotate: -45deg
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%
}

.transition {
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-box-shadow, -webkit-transform
}

.transition-colors {
  -webkit-transition-property: background-color, border-color, color, fill, stroke;
  transition-property: background-color, border-color, color, fill, stroke
}

.transition-opacity {
  -webkit-transition-property: opacity;
  transition-property: opacity
}

.transition-transform {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform
}

.transition-height {
  -webkit-transition-property: height;
  transition-property: height
}

.duration-200 {
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms
}

.duration-300 {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms
}

.duration-base {
  -webkit-transition-duration: 275ms;
          transition-duration: 275ms
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite
}

.animate-pulse {
  -webkit-animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

@media (min-width: 640px) {
  .sm\:inline-block {
    display: inline-block
  }

  .sm\:inline {
    display: inline
  }

  .sm\:inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .sm\:table-cell {
    display: table-cell
  }

  .sm\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row
  }

  .sm\:p-6 {
    padding: 1.5rem
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }

  .sm\:text-right {
    text-align: right
  }

  .sm\:w-64 {
    width: 16rem
  }

  .sm\:w-96 {
    width: 24rem
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6
  }
}

@media (min-width: 768px) {
  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--space-y-reverse))
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .md\:bg-pattern {
    background-image: url('/assets/bg-pattern.svg')
  }

  .md\:block {
    display: block
  }

  .md\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .md\:inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .md\:table-cell {
    display: table-cell
  }

  .md\:grid {
    display: grid
  }

  .md\:hidden {
    display: none
  }

  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start
  }

  .md\:items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center
  }

  .md\:justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start
  }

  .md\:justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end
  }

  .md\:text-base {
    font-size: 16px
  }

  .md\:text-lg {
    font-size: 20px
  }

  .md\:text-2xl {
    font-size: 26px
  }

  .md\:text-3xl {
    font-size: 32px
  }

  .md\:mb-0 {
    margin-bottom: 0
  }

  .md\:mr-3 {
    margin-right: 0.75rem
  }

  .md\:mr-4 {
    margin-right: 1rem
  }

  .md\:ml-4 {
    margin-left: 1rem
  }

  .md\:mr-5 {
    margin-right: 1.25rem
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem
  }

  .md\:ml-5 {
    margin-left: 1.25rem
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem
  }

  .md\:ml-auto {
    margin-left: auto
  }

  .md\:max-w-96 {
    max-width: 24rem
  }

  .md\:p-1 {
    padding: 0.25rem
  }

  .md\:p-4 {
    padding: 1rem
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem
  }

  .md\:pt-0 {
    padding-top: 0
  }

  .md\:pb-0 {
    padding-bottom: 0
  }

  .md\:pt-6 {
    padding-top: 1.5rem
  }

  .md\:pr-8 {
    padding-right: 2rem
  }

  .md\:pt-10 {
    padding-top: 2.5rem
  }

  .md\:pr-10 {
    padding-right: 2.5rem
  }

  .md\:pt-14 {
    padding-top: 3.5rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-auto {
    width: auto
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-2\/3 {
    width: 66.666667%
  }

  .md\:w-1\/3 {
    width: 33.333333%
  }

  .md\:w-full {
    width: 100%
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem;
    grid-column-gap: 1.5rem;
    -webkit-column-gap: 1.5rem;
       -moz-column-gap: 1.5rem;
            column-gap: 1.5rem
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7
  }
}

@media (min-width: 1024px) {
  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0px * var(--space-y-reverse))
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--space-y-reverse))
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(2rem * var(--space-y-reverse))
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse)))
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse)));
    margin-bottom: calc(4rem * var(--space-y-reverse))
  }

  .lg\:block {
    display: block
  }

  .lg\:flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
  }

  .lg\:inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
  }

  .lg\:hidden {
    display: none
  }

  .lg\:flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column
  }

  .lg\:flex-wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap
  }

  .lg\:items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start
  }

  .lg\:items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end
  }

  .lg\:items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center
  }

  .lg\:justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center
  }

  .lg\:flex-1 {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 0%;
            flex: 1 1 0%
  }

  .lg\:order-last {
    -webkit-box-ordinal-group: 10000;
        -ms-flex-order: 9999;
            order: 9999
  }

  .lg\:h-32 {
    height: 8rem
  }

  .lg\:h-auto {
    height: auto
  }

  .lg\:text-base {
    font-size: 16px
  }

  .lg\:text-md {
    font-size: 18px
  }

  .lg\:text-lg {
    font-size: 20px
  }

  .lg\:text-xl {
    font-size: 22px
  }

  .lg\:text-2xl {
    font-size: 26px
  }

  .lg\:text-4xl {
    font-size: 38px
  }

  .lg\:text-5xl {
    font-size: 46px
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem
  }

  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem
  }

  .lg\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem
  }

  .lg\:mb-0 {
    margin-bottom: 0
  }

  .lg\:mb-2 {
    margin-bottom: 0.5rem
  }

  .lg\:mr-5 {
    margin-right: 1.25rem
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem
  }

  .lg\:mt-8 {
    margin-top: 2rem
  }

  .lg\:mt-10 {
    margin-top: 2.5rem
  }

  .lg\:ml-12 {
    margin-left: 3rem
  }

  .lg\:mb-16 {
    margin-bottom: 4rem
  }

  .lg\:-ml-4 {
    margin-left: -1rem
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem
  }

  .lg\:-mr-gs {
    margin-right: calc(var(--gutter-size) * -1)
  }

  .lg\:max-w-none {
    max-width: none
  }

  .lg\:max-w-xs {
    max-width: 20rem
  }

  .lg\:min-w-0 {
    min-width: 0
  }

  .lg\:min-w-full {
    min-width: 100%
  }

  .lg\:min-w-xxxs {
    min-width: 8.25rem
  }

  .lg\:min-w-xxs {
    min-width: 12.5rem
  }

  .lg\:p-5 {
    padding: 1.25rem
  }

  .lg\:p-7 {
    padding: 1.75rem
  }

  .lg\:p-8 {
    padding: 2rem
  }

  .lg\:p-15 {
    padding: 3.75rem
  }

  .lg\:p-7\.5 {
    padding: 1.875rem
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem
  }

  .lg\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem
  }

  .lg\:px-7\.5 {
    padding-left: 1.875rem;
    padding-right: 1.875rem
  }

  .lg\:pt-0 {
    padding-top: 0
  }

  .lg\:pt-2 {
    padding-top: 0.5rem
  }

  .lg\:pl-4 {
    padding-left: 1rem
  }

  .lg\:pt-5 {
    padding-top: 1.25rem
  }

  .lg\:pr-5 {
    padding-right: 1.25rem
  }

  .lg\:pl-6 {
    padding-left: 1.5rem
  }

  .lg\:pb-8 {
    padding-bottom: 2rem
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem
  }

  .lg\:pl-10 {
    padding-left: 2.5rem
  }

  .lg\:pt-14 {
    padding-top: 3.5rem
  }

  .lg\:pb-15 {
    padding-bottom: 3.75rem
  }

  .lg\:pt-28 {
    padding-top: 7rem
  }

  .lg\:pb-42 {
    padding-bottom: 10.5rem
  }

  .lg\:pt-5\/8 {
    padding-top: 62.5%
  }

  .lg\:sticky {
    position: -webkit-sticky;
    position: sticky
  }

  .lg\:top-5 {
    top: 1.25rem
  }

  .lg\:w-48 {
    width: 12rem
  }

  .lg\:w-auto {
    width: auto
  }

  .lg\:w-1\/2 {
    width: 50%
  }

  .lg\:w-2\/3 {
    width: 66.666667%
  }

  .lg\:w-1\/3 {
    width: 33.333333%
  }

  .lg\:w-1\/4 {
    width: 25%
  }

  .lg\:w-full {
    width: 100%
  }

  .lg\:gap-gs {
    grid-gap: var(--gutter-size);
    grid-gap: var(--gutter-size);
    gap: var(--gutter-size)
  }

  .lg\:gap-x-gs {
    grid-column-gap: var(--gutter-size);
    grid-column-gap: var(--gutter-size);
    -webkit-column-gap: var(--gutter-size);
       -moz-column-gap: var(--gutter-size);
            column-gap: var(--gutter-size)
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0;
    grid-row-gap: 0;
    row-gap: 0
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12
  }

  .lg\:col-start-2 {
    grid-column-start: 2
  }

  .lg\:col-start-3 {
    grid-column-start: 3
  }

  .lg\:col-start-9 {
    grid-column-start: 9
  }

  .lg\:col-end-8 {
    grid-column-end: 8
  }

  .lg\:row-start-1 {
    grid-row-start: 1
  }

  .lg\:row-start-2 {
    grid-row-start: 2
  }

  .lg\:row-start-auto {
    grid-row-start: auto
  }
}

@media (min-width: 1280px) {
  .xl\:text-sm {
    font-size: 14px
  }

  .xl\:text-md {
    font-size: 18px
  }

  .xl\:text-lg {
    font-size: 20px
  }

  .xl\:w-full {
    width: 100%
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem;
    grid-column-gap: 3rem;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10
  }

  .xl\:col-start-2 {
    grid-column-start: 2
  }
}

@media (min-width: 375px) {
}
