<template>
  <div>
    <input type="hidden" name="fields[bodyFlexible][new1][enabled]" value="1" />
    <input type="hidden" name="fields[bodyFlexible][new1][type]" value="body" />
    <input
      type="hidden"
      name="fields[bodyFlexible][new1][fields][body]"
      :value="content"
    />
    <quill-editor v-model="content" :options="editorOptions">
      <div id="toolbar" slot="toolbar">
        <button class="ql-header" value="2"></button>
        <button class="ql-header" value="3">
          <svg viewBox="0 0 18 18" role="img">
            <path
              class="ql-fill"
              d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"
            />
          </svg>
        </button>
        <button class="ql-bold">Bold</button>
        <button class="ql-italic">Italic</button>
        <button class="ql-list" value="bullet">Bullet List</button>
        <button class="ql-list" value="ordered">
          <svg viewbox="0 0 18 18" role="img">
            <line class="ql-stroke" x1="7" x2="15" y1="4" y2="4" />
            <line class="ql-stroke" x1="7" x2="15" y1="9" y2="9" />
            <line class="ql-stroke" x1="7" x2="15" y1="14" y2="14" />
            <line
              class="ql-stroke ql-thin"
              x1="2.5"
              x2="4.5"
              y1="5.5"
              y2="5.5"
            />
            <path
              class="ql-fill"
              d="M3.5,6A0.5,0.5,0,0,1,3,5.5V3.085l-0.276.138A0.5,0.5,0,0,1,2.053,3c-0.124-.247-0.023-0.324.224-0.447l1-.5A0.5,0.5,0,0,1,4,2.5v3A0.5,0.5,0,0,1,3.5,6Z"
            />
            <path
              class="ql-stroke ql-thin"
              d="M4.5,10.5h-2c0-.234,1.85-1.076,1.85-2.234A0.959,0.959,0,0,0,2.5,8.156"
            />
            <path
              class="ql-stroke ql-thin"
              d="M2.5,14.846a0.959,0.959,0,0,0,1.85-.109A0.7,0.7,0,0,0,3.75,14a0.688,0.688,0,0,0,.6-0.736,0.959,0.959,0,0,0-1.85-.109"
            />
          </svg>
        </button>
        <button class="ql-link">
          <svg viewbox="0 0 18 18" role="img">
            <line class="ql-stroke" x1="7" x2="11" y1="7" y2="11" />
            <path
              class="ql-even ql-stroke"
              d="M8.9,4.577a3.476,3.476,0,0,1,.36,4.679A3.476,3.476,0,0,1,4.577,8.9C3.185,7.5,2.035,6.4,4.217,4.217S7.5,3.185,8.9,4.577Z"
            />
            <path
              class="ql-even ql-stroke"
              d="M13.423,9.1a3.476,3.476,0,0,0-4.679-.36,3.476,3.476,0,0,0,.36,4.679c1.392,1.392,2.5,2.542,4.679.36S14.815,10.5,13.423,9.1Z"
            />
          </svg>
        </button>
      </div>
    </quill-editor>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    quillEditor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  created() {
    if (this.value) {
      this.content = this.value;
    }
  },
  data() {
    return {
      content: "",
      editorOptions: {
        placeholder: "",
        rows: 5,
        modules: {
          toolbar: "#toolbar",
        },
      },
    };
  },
};
</script>

<style>
.ql-editor {
  @apply py-3 px-4 min-h-md;
}

.ql-editor {
  @apply font-sans text-sm leading-tight;

  @screen lg {
    @apply text-base;
  }
}

.ql-editor p,
.ql-editor ul,
.ql-editor ol {
  @apply mb-5;
}

.ql-editor h2 {
  @apply text-2xl font-bold leading-tight mb-7;
}

.ql-editor h3 {
  @apply text-lg font-bold leading-tight mb-5;
}
</style>
