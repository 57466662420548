.bottom-spacing-none {
  @apply mb-0;
}

.bottom-spacing-sm {
  @apply mb-7.5;
}

.bottom-spacing-md {
  @apply mb-10;

  @screen lg {
    @apply mb-15;
  }
}

.bottom-spacing-lg {
  @apply mb-15;

  @screen lg {
    @apply mb-22.5;
  }
}