<template>
  <li>
    <div class="pb-4 space-y-4 md:space-y-0 result-grid md:gap-x-6 gap-y-2">
      <a :href="result.url" class="link block">
        <h3 class="font-bold text-md lg:text-lg leading-tight">
          <span v-if="result.pageHeaderTitle" v-html="result.pageHeaderTitle" />
          <span v-else>{{ result.title }}</span>
        </h3>
      </a>
      <div class="hidden md:inline-flex justify-end row-span-2">
        <a
          :href="result.url"
          class="h-8 w-8 -m-2 flex items-center justify-center link"
        >
          <span class="sr-only">View {{ result.title }}</span>
          <span aria-hidden="true" class="w-5 h-5 text-blue block">
            <IconChevronRight />
          </span>
        </a>
      </div>
      <div class="col-start-1 min-w-0">
        <p v-if="result.summary" class="truncate" v-html="result.summary" />
      </div>
    </div>
    <div class="h-px bg-divider-blue mb-4"></div>
  </li>
</template>
<script>
import IconChevronRight from "../../../templates/_includes/svg/icon-chevron-right.svg";

export default {
  name: "SearchResultItem",
  components: { IconChevronRight },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
@screen md {
  .result-grid {
    @apply grid items-center;
    grid-template-columns: auto 8rem;
    grid-template-rows: auto;
  }
}
</style>
