.field {
  @apply appearance-none block w-full border border-gray-medium rounded-none py-3 px-4 leading-tight transition duration-base;
}

.field:hover {
  @apply border-blue shadow-m2;
}

.field--error,
.field--error:hover,
.field--error:focus {
  @apply border-red;
}

.field-errors {
  @apply text-sm text-red mt-2;
}
