<template>
  <transition name="flyout-menu-mask-transition">
    <div
      v-if="active"
      class="flyout-menu-mask fixed inset-x-0 bottom-0 bg-black bg-opacity-50"
      @click="$emit('click')"
    ></div>
  </transition>
</template>

<script>
export default {
  name: "FlyoutMenuMask",
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.flyout-menu-mask {
  top: theme("height.header-compact");
  z-index: 8888;
}

@screen lg {
  .flyout-menu-mask {
    @apply top-0;
  }
}

.flyout-menu-mask-transition-enter,
.flyout-menu-mask-transition-leave-to {
  @apply opacity-0;
}

.flyout-menu-mask-transition-enter-active,
.flyout-menu-mask-transition-leave-active {
  @apply transition-opacity duration-300;
}

.flyout-menu-mask-transition-enter-to,
.flyout-menu-mask-transition-leave {
  @apply opacity-100;
}
</style>
