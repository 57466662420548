.slider {
  @apply flex overflow-y-hidden overflow-x-auto;
}

.slider-start {
  width: calc(((100vw - var(--container-size)) / 2));
  @apply flex-shrink-0;
}

.slider-end {
  width: var(--gutter-size);
  @apply flex-shrink-0;
}

.slider-card {
  min-height: 100%;
  width: 19rem;
  @apply mr-2.5 flex-shrink-0;
}

@screen sm {
  .slider-card {
    width: calc((var(--container-size) - (var(--gutter-size) * 4)) / 3);
    @apply mr-gs;
  }
  .slider--limit-2 .slider-card {
    width: calc((var(--container-size) - (var(--gutter-size) * 3)) / 2);
  }
}

.slider-card--alt {
  width: 14.5rem;
}

.slider-button {
  @apply bg-blue-bright rounded-full w-11 h-11 py-2 px-3 text-white transition duration-base;
}

.slider-button:hover {
  @apply bg-opacity-50;
}
