<template>
  <div
    ref="embedContainer"
    class="pbi-embed pb-10"
    :style="{ height: `${iframeScrollHeight}px` }"
  ></div>
</template>

<script>
import * as pbi from "powerbi-client";

export default {
  name: "FlexibleContentPowerBiEmbed",

  props: {
    embedUrl: { type: String, required: true },
    entryId: { type: String, required: true },
    pageName: { type: String, required: false, default: "" },
    showFilters: { type: Boolean, required: false, default: false },
    showPageNavigation: { type: Boolean, required: false, default: false },
  },

  data() {
    return {
      reportId: null,
      groupId: null,
      iframeScrollHeight: 0,
    };
  },

  mounted() {
    this.embed();
  },

  methods: {
    async embed() {
      const url = new URL(this.embedUrl);
      this.reportId = url.searchParams.get("reportId");
      this.groupId = url.searchParams.get("groupId");

      const accessToken = await this.fetchAccessToken();

      const permissions = pbi.models.Permissions.Read;

      const config = {
        type: "report",
        tokenType: pbi.models.TokenType.Embed,
        accessToken,
        embedUrl: this.embedUrl,
        id: this.reportId,
        permissions,
        settings: {
          panes: {
            filters: {
              visible: this.showFilters,
            },
            pageNavigation: {
              visible: this.showPageNavigation,
            },
          },
        },
      };

      if (this.pageName) config.pageName = this.pageName;

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory
      );
      // Embed the report and display it within the div container.
      const embed = powerbi.embed(this.$refs.embedContainer, config);

      embed.on("rendered", () => {
        const { iframe } = embed;

        this.iframeScrollHeight = Math.min(
          iframe.contentWindow.top.document.body.scrollHeight,
          1650
        );
      });
    },

    async fetchAccessToken() {
      const response = await fetch(
        `/admin/actions/core-module/core?reportId=${this.reportId}&groupId=${this.groupId}&entryId=${this.entryId}`
      );
      const data = await response.json();
      return data.token || undefined;
    },
  },
};
</script>

<style scoped>
.pbi-embed {
  min-height: 500px;
}

@media only screen and (min-width: 600px) {
  .pbi-embed {
    min-height: 900px;
  }
}
</style>
