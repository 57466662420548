.checkbox {
  @apply flex items-center cursor-pointer rounded-sm;
  @apply transition-colors duration-100;
}

.checkbox:focus .checkbox__label {
  @apply text-blue;
}

.checkbox:focus .checkbox__icon {
  @apply border-blue;
}

.checkbox__el {
  @apply sr-only;
}

.checkbox__label {
  @apply block leading-tight break-words text-left;
  @apply transition-colors duration-100;
}

.checkbox__icon {
  @apply flex items-center justify-center flex-shrink-0;
  @apply w-4.5 h-4.5 mr-2.5 p-2px;
  @apply text-white border border-gray-medium rounded-sm;
  @apply transition duration-100;
}

.checkbox__icon--large {
  @apply w-8 h-8 mr-2.5 p-4px;
}

.checkbox__icon svg {
  @apply w-full h-full fill-current opacity-0;
  @apply transition duration-100;
}

.checkbox__el:checked + .checkbox__icon,
.checkbox--checked .checkbox__icon {
  @apply border-blue bg-blue;
}

.checkbox__el:checked + .checkbox__icon svg,
.checkbox--checked .checkbox__icon svg {
  @apply opacity-100;
}
