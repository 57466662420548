/* --- PRIMARY --- */

.btn-primary {
  /*NOTE, min-height & min-width might need tweaking as more pages/components are built out*/
  min-height: 2.8125rem; /*45px*/
  min-width: 12.5rem; /*200px*/
  @apply cursor-pointer;
  @apply inline-flex items-center justify-center px-5 py-2;
  @apply bg-red border border-white rounded-sm shadow-m3;
  @apply text-lg text-white text-center font-bold leading-tight;
  @apply transition duration-base;
}

.btn-primary--small {
  min-width: 9.375rem; /*150px*/
}

.btn-primary:hover {
  @apply bg-red-dark shadow-m4;
}

/* --- SECONDARY --- */

.btn-secondary {
  /*NOTE, min-height & min-width might need tweaking as more pages/components are built out*/
  min-height: 2.5em; /*40px*/
  min-width: 10.5rem; /*168px*/
  @apply cursor-pointer;
  @apply inline-flex items-center justify-center px-10 py-2;
  @apply bg-gray-alt-2 rounded-sm;
  @apply text-md text-white text-center font-bold leading-tight;
  @apply transition-colors duration-base;
}

.btn-secondary:hover {
  @apply bg-gray-darker;
}

/* --- TERTIARY  --- */

.btn-tertiary {
  height: 2.8125rem; /*45px*/
  @apply relative text-base inline-flex items-center justify-center px-4 py-2;
  @apply bg-white border border-gray shadow-none;
  @apply transition duration-base;
}

.btn-tertiary__icon {
  width: 0.6875rem;
  @apply text-gray-dark;
  @apply absolute inset-y-0 right-0 mr-4;
  @apply flex items-center;
  @apply pointer-events-none;
  @apply transition duration-base;
}

@screen lg {
  .btn-tertiary {
    @apply text-md;
  }
}

.btn-tertiary:hover {
  @apply border-blue shadow-m2;
}

.btn-tertiary[disabled] {
  @apply opacity-25 pointer-events-none;
}

.btn-tertiary--active {
  @apply bg-gray-lighter;
}

/* --- LINK  --- */

.btn-link {
  @apply inline-flex items-center;
  @apply text-md text-blue font-bold leading-tight;
  @apply transition-colors duration-base;
}

.btn-link__icon {
  @apply ml-3 w-3 flex-shrink-0 text-gray-dark;
}

.btn-link:hover {
  @apply text-gray-dark;
}

.btn-link--alt {
  @apply text-xl text-gray-dark;
}

.btn-link--alt:hover {
  @apply text-blue;
}

.btn-link--alt .btn-link__icon {
  @apply w-4 text-blue;
}

.btn-link--white {
  @apply text-white transition-opacity;
}

.btn-link--white .btn-link__icon {
  @apply text-white;
}

.btn-link--white.btn-link:hover {
  @apply text-white opacity-75;
}

/* --- PILL  --- */

.btn-pill {
  min-height: 1.875rem; /*30px*/
  @apply inline-flex items-center justify-center px-4 py-1;
  @apply bg-gray-lighter rounded-full;
  @apply text-blue text-center font-bold leading-tight;
  @apply transition-colors duration-base;
}

.btn-pill:hover {
  @apply bg-blue text-white;
}

/* 😱 Don't look at this... */

@screen lg {
  .select--alt .btn-tertiary__icon {
    @apply w-5 mr-5;
  }
}
