.select {
  height: 2.8125rem; /*45px*/
  @apply relative w-full bg-white;
}

.select__icon {
  width: 0.6875rem;
  @apply absolute inset-y-0 right-0 mr-4;
  @apply flex items-center;
  @apply pointer-events-none;
  @apply transition duration-base;
}

.select__el {
  padding-left: 1rem;
  padding-right: calc(1rem + 0.6875rem + 1rem);
  @apply text-base block w-full h-full;
  @apply appearance-none cursor-pointer;
  @apply bg-white border border-gray rounded-none shadow-none;
  @apply transition duration-base;
}

@screen lg {
  .select__el {
    @apply text-md;
  }
}

.select__el:hover {
  @apply border-blue shadow-m2;
}

.select__el[disabled] {
  @apply opacity-25 pointer-events-none;
}

.select__el[disabled] + .select__icon {
  @apply opacity-25;
}

.select--alt .select__el {
  @apply text-md border-none shadow-m2;
  border-radius: 2px;
}

@screen lg {
  .select--alt .select__el {
    @apply text-xl pr-15;
  }

  .select--alt .select__icon {
    @apply w-5 mr-5;
  }
}

.select--alt .select__el:hover {
  @apply border-blue shadow-m3;
}
