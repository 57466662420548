.search {
  @apply w-full relative;
}

.search__border {
  @apply absolute inset-x-0 bottom-0 h-px px-1;
  @apply transition-opacity duration-base;
}

.search__input {
  @apply block w-full pr-12 py-3;
  @apply font-bold text-xl text-gray-dark;
  @apply border-b border-gray-dark rounded-none;
  @apply transition-colors duration-base;
}

.search__input::placeholder {
  @apply opacity-100 text-gray-dark;
  @apply transition-opacity duration-base;
}

.search__input:focus::placeholder {
  @apply opacity-50;
}

.search__button {
  @apply absolute inset-y-0 right-0 w-6 h-full flex items-center justify-end;
}

.search__button button {
  @apply h-9 text-blue-bright p-0;
  @apply transition-colors duration-base;
}

.search__button button:hover {
  @apply text-blue;
}
