<template>
  <div data-testid="keyword-filter" class="relative">
    <div
      :id="`${name}-filter-display-label`"
      class="block text-sm md:text-base lg:text-lg font-bold mb-1 lg:mb-2"
      v-if="label.length > 0"
    >
      <span class="sr-only">Select </span>{{ label }}
    </div>
    <div class="bg-white">
      <div
        ref="toggle"
        aria-haspopup="true"
        :aria-owns="`${name}-listbox`"
        :aria-labelledby="`${name}-filter-display-label`"
        :aria-expanded="String(isOpen)"
        role="combobox"
        tabindex="0"
        :disabled="disabled"
        class="btn-tertiary w-full min-w-0 justify-start"
        :class="inputClass"
        @click.stop="openFilters"
        @keydown.up.down.space.prevent="openFilters"
      >
        <span class="sr-only">Selected: </span>
        <span class="truncate" :class="valueClass">{{
          displayValue || placeholder
        }}</span>
        <span class="btn-tertiary__icon">
          <IconChevronDown />
        </span>
      </div>
    </div>

    <portal v-if="isOpen" to="modal">
      <div class="fixed inset-0 z-60">
        <div class="w-full h-full flex items-center justify-center p-ogs">
          <SiteOverlay @click="closeFilters" />
          <focus-trap :active="isOpen" :initial-focus="() => $refs.options">
            <div
              class="BaseMultiselectFilterModal__modal-container"
              role="group"
              tabindex="-1"
              :aria-labelledby="`${name}-heading`"
              @click.stop
              @keydown.esc="closeFilters"
            >
              <div class="BaseMultiselectFilterModal__modal-container-head">
                <h3
                  :id="`${name}-heading`"
                  class="text-base md:text-lg lg:text-xl font-bold capitalize"
                >
                  {{ name }}
                </h3>
                <div
                  class="absolute left-0 inset-y-0 flex items-center ml-1 md:ml-5"
                >
                  <button
                    type="button"
                    class="text-xs link underline text-black p-1.5 md:hidden"
                    @click="clearFilters"
                  >
                    Clear
                  </button>
                </div>
                <div
                  class="absolute right-0 inset-y-0 flex items-center mr-1 md:mr-5"
                >
                  <button
                    class="w-8 h-8 link text-black p-1.5 md:p-1"
                    type="button"
                    @click="closeFilters"
                  >
                    <svg
                      class="w-full h-full stroke-current stroke-2"
                      viewBox="0 0 32 32"
                      role="img"
                    >
                      <path d="m6 6 20 20"></path>
                      <path d="m26 6-20 20"></path>
                    </svg>
                  </button>
                </div>
              </div>
              <div class="BaseMultiselectFilterModal__modal-container-main">
                <ul
                  :id="`${name}-listbox`"
                  ref="options"
                  class="grid md:grid-cols-2 gap-x-4 p-1 md:p-4"
                  role="listbox"
                  :aria-labelledby="`${name}-filter-display-label`"
                  aria-multiselectable="true"
                  :aria-activedescendant="
                    activeOption ? activeOption.id : false
                  "
                  tabindex="0"
                  @keydown.up.prevent="moveActiveOptionIndex('UP')"
                  @keydown.down.prevent="moveActiveOptionIndex('DOWN')"
                  @keydown.enter.space.prevent="
                    toggleOption(options[activeOptionIndex])
                  "
                >
                  <li
                    v-for="(option, index) in options"
                    :id="`${option.id}-option`"
                    :key="option.id"
                    :aria-selected="String(selected.includes(option.id))"
                    role="option"
                    class="checkbox p-2"
                    :class="{
                      'checkbox--checked': selected.includes(option.id),
                      'bg-blue text-white': activeOptionIndex === index,
                    }"
                    @click="toggleOption(option)"
                    @keydown.space.prevent="toggleOption(option)"
                  >
                    <div class="checkbox__icon">
                      <TickIcon />
                    </div>
                    <span class="checkbox__label text-md">{{
                      option.title
                    }}</span>
                  </li>
                </ul>
              </div>
              <div class="BaseMultiselectFilterModal__modal-container-foot">
                <button
                  type="button"
                  class="hidden md:block link underline text-black"
                  @click="clearFilters"
                >
                  Clear all
                </button>
                <button
                  class="w-full md:w-auto btn-primary"
                  type="button"
                  @click="applyFilters"
                >
                  Apply
                </button>
              </div>
            </div>
          </focus-trap>
        </div>
      </div>
    </portal>
  </div>
</template>
<script>
import { FocusTrap } from "focus-trap-vue";
import SiteOverlay from "../SiteOverlay.vue";
import IconChevronDown from "../../../../templates/_includes/svg/icon-chevron-down.svg";
import TickIcon from "../../../../templates/_includes/svg/icon-tick.svg";

export default {
  name: "BaseMultiselectFilterModal",

  components: { FocusTrap, SiteOverlay, IconChevronDown, TickIcon },

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => "Any",
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      required: true,
    },
    inputClass: {
      type: String,
      required: false,
      default: () => "",
    },
    valueClass: {
      type: String,
      required: false,
      default: () => "",
    },
  },

  data() {
    return {
      activeOption: null,
      activeOptionIndex: 0,
      isOpen: false,
      original: [...this.value],
      selected: [...this.value],
    };
  },

  computed: {
    displayValue() {
      return this.options
        .filter((option) => this.selected.includes(option.id))
        .map((option) => option.title)
        .join(", ");
    },
  },

  watch: {
    value(value) {
      this.selected = value;
    },
  },

  destroyed() {
    window.removeEventListener("click", this.closeFilters);
  },

  methods: {
    openFilters() {
      this.isOpen = true;
      this.setScrollLock();
    },

    applyFilters() {
      this.isOpen = false;
      this.$emit("change", {
        target: {
          name: this.name,
          value: this.selected,
        },
      });
      this.original = [...this.selected];
      this.removeScrollLock();
    },

    clearFilters() {
      this.selected = [];
    },

    closeFilters() {
      this.isOpen = false;
      this.selected = [...this.original];
      this.removeScrollLock();
    },

    setScrollLock() {
      document.body.classList.add("overflow-hidden");
    },

    removeScrollLock() {
      document.body.classList.remove("overflow-hidden");
    },

    moveActiveOptionIndex(direction) {
      const offset = direction === "DOWN" ? 1 : -1;
      const newIndex = this.activeOptionIndex + offset;

      if (newIndex > this.options.length - 1 || newIndex === -1) return;
      this.activeOptionIndex = newIndex;
    },

    toggleOption(option) {
      const index = this.selected.findIndex(
        (selection) => selection === option.id
      );

      if (index === -1) {
        this.selected.push(option.id);
      } else {
        this.selected.splice(index, 1);
      }
    },
  },
};
</script>

<style>
.BaseMultiselectFilterModal__modal-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: "head" "main" "foot";
  position: relative;
  max-height: calc(100vh - (var(--outside-gutter-size) * 2));
  max-width: 768px;
  background: white;
  @apply shadow-m3 z-20;
}

@screen md {
  .BaseMultiselectFilterModal__modal-container {
    max-height: calc(100vh - (var(--outside-gutter-size) * 4));
  }
}

.BaseMultiselectFilterModal__modal-container-head {
  position: relative;
  text-align: center;
  grid-area: head;
  @apply border-b border-gray-medium;
  @apply px-3 py-3;
}

.BaseMultiselectFilterModal__modal-container-main {
  grid-area: main;
  overflow-x: hidden;
  overflow-y: auto;
}

.BaseMultiselectFilterModal__modal-container-foot {
  grid-area: foot;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @apply border-t border-gray-medium;
  @apply px-3 py-2;
}

@screen md {
  .BaseMultiselectFilterModal__modal-container-head,
  .BaseMultiselectFilterModal__modal-container-foot {
    @apply px-5 py-4;
  }
}
</style>
