.container {
  @apply mx-auto px-ogs;
  max-width: var(--container-size);
}

.container--lg {
  max-width: calc(var(--container-size) + 9rem);
}

.container--sm {
  max-width: calc(var(--container-size) - 34rem);
}

.container--xs {
  max-width: calc(var(--container-size) - 48rem);
}
